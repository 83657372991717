import { CircularProgress } from '@mui/material';
import { Card, Input, Select } from 'antd';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getAPICall } from 'shared';
import { openNotificationWithIcon } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import Search from 'antd/es/input/Search';

const ThirdParty = () => {
  const [tableData, setTableData] = useState([]);
  const [pending, setPending] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [companyList, setCompanyList] = useState([]);
  const [hitList, setHitList] = useState([]);
  const [listdata, setListData] = useState([]);
  const [selectedCompany_id, setSelectedCompanyId] = useState(null);


  const AvalibeLimit  = (props)=>{

    const [loading,setLoading] = useState(false);
    // eslint-disable-next-line react/prop-types
    const [vlaue,setVlaue ] = useState(props.row?.count_limit);



    return     <Search value={vlaue} loading={loading} type={"number"} placeholder="Increase The Count " enterButton="add" n onChange={(e)=>setVlaue(e.target.value)} onSearch={
      async (value, _e, info) =>{
        // eslint-disable-next-line react/prop-types
        let parsedData  = parseInt(value);

        // eslint-disable-next-line react/prop-types
        console.log("company Id",props.companyID)
        // eslint-disable-next-line react/prop-types
        if (props.companyID === null || props.companyID === undefined || parsedData < 0){
          return;
        }
        console.log(value)
        // eslint-disable-next-line react/prop-types
        console.log(props.row);
        setLoading(true);
        let data = {
          // eslint-disable-next-line react/prop-types
          company_id: props?.companyID,
          // eslint-disable-next-line react/prop-types
          api_name: props.row?.apiName,
          count: parsedData,
        }
        let headers  = {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
        let options = {
          headers: headers,
          method: 'POST',
          body: JSON.stringify(data),
        }



        let response =  await  fetch(`${URL_WITH_VERSION}accounts/increase-api-count`,options);
        if (response.status === 200) {
          response = await response.json();
          setLoading(false);
        }

      }
    }  />
  }


  const columns = [
    {
      name: <strong>API Name</strong>,
      sortable: true,
      center: 'true',
      cell: (row) => row?.apiName,
    },
    {
      name : "Available Limits",
      sortable: true,
      center: 'true',
      cell:(row)=>{
        return <AvalibeLimit row={row}  companyID = {selectedCompany_id}/>;
      }
    },
    {
      name: <strong>API Source</strong>,
      center: 'true',
      cell: (row) => row?.api_owner,
    },
    {
      name: <strong>Total Limit (per month)</strong>,
      sortable: true,
      center: 'true',
      cell: (row) => row?.totalLimit,
    },
    {
      name: <strong>Total Hit (this month)</strong>,
      sortable: true,
      center: 'true',
      cell: (row) => row?.totalHit,
    },
    {
      name: <strong>Remaining hits (this month)</strong>,
      sortable: true,
      center: 'true',
      cell: (row) => row?.totalLimit - row?.totalHit,
    },
  ];

  useEffect(() => {
    getTableData();
    getCompanyList();
  }, []);

  const getTableData = async () => {
    setPending(true);
    try {
      const authtoken = localStorage.getItem('token');
      const requestOptions = {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authtoken, // Include the Authorization header with the token
        },
        redirect: 'follow', // Include the request body
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/get-counts-marine-hits`,
        requestOptions,
      );
      const respdata = await resp.json();
      setTableData(respdata);
      setListData(respdata);

      setPending(false);
    } catch (err) {
      openNotificationWithIcon(
        'error',
        err.message || 'An error occurred while fetching table data.',
      );
    }
  };

  const getCompanyList = async () => {
    setPending(true);
    try {
      const requestOptions = {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow', // Include the request body
      };
      let url = `${URL_WITH_VERSION}accounts/company-details`;
      const resp = await fetch(url, requestOptions);
      const respdata = await resp.json();
      setCompanyList(respdata);

      setPending(false);
    } catch (err) {
      openNotificationWithIcon(
        'error',
        err.message || 'An error occurred while fetching table data.',
      );
    }
  };


  // const filteredData = listdata?.filter((row) => {
  //   const values = Object.values(row).join(' ');
  //   return values.toLowerCase().includes(searchValue.toLowerCase());
  // });

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getCompanyHits = async(company) =>{
    setPending(true);
    try {
      const requestOptions = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
      };
      let url = `${URL_WITH_VERSION}accounts/counts-marine-from-db-company-wise`;
      setSelectedCompanyId(company);
      const resp = await fetch(url,
        {
          body: JSON.stringify({ id: company }),
          ...requestOptions
        },
      );
      const respdata = await resp.json();
      console.log(respdata);
        setListData(respdata)
        setPending(false)
    } catch (err) {
      openNotificationWithIcon(
        'error',
        err.message || 'An error occurred while fetching table data.',
      );
    }
  }

  const handleClear = () => {
    setPending(true)
    setListData(tableData)
    setPending(false)
  }

  const filterOption = (input, option) => option?.children?.toLowerCase().includes(input.toLowerCase());

  console.log("liisst data", listdata)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* <label style={{whiteSpace: 'nowrap'}} htmlFor="search"><b>Search : &nbsp;</b></label> */}
          {/* <MDInput
            type="text"
            style={{ minWidth: '200px' }}
            name="search"
            id="search"
            value={searchValue}
            onChange={handleSearch}
            label="Search here"
          /> */}

          <div>
            <label
              style={{
                fontSize: '14px',
                fontWeight: '600',
                marginRight: '10px',
              }}
              htmlFor="serach_company"
            >
              Filter Company wise :{' '}
            </label>
            <Select
              id="serach_company"
              showSearch
              placeholder="Select company"
              allowClear
              popupMatchSelectWidth={false}
              style={{ minWidth: '240px' }}
              onSelect={(value) => getCompanyHits(value)}
              onClear={() => handleClear()}
              filterOption={filterOption}
            >
              {companyList.length > 0 &&
                companyList.map((item) => (
                  <Select.Option key={item.company_name} value={item.id}>
                    {item.company_name}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </div>
      </MDBox>

      <Card>
        <MDBox>
          <DataTable
            columns={columns}
            data={listdata}
            pagination
            progressPending={pending}
            progressComponent={<CircularProgress />}
            responsive
            highlightOnHover
            expandOnRowClicked
          />
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};

export default ThirdParty;

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { getAllRolesService } from "services/all";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import DataTable from "react-data-table-component";

// Data
import { useEffect, useState } from "react";
import { getAPICall } from "shared";
import { URL_WITH_VERSION } from "shared";
import { Box, Button, CircularProgress, Icon, Input, Modal, SvgIcon, Switch, TextField } from "@mui/material";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { postAPICall } from "shared";
import { openNotificationWithIcon } from "shared";
import WhatsAppBulkMessageSender from "./WhatsAppBulkMessageSender";
import { Typography } from "antd";
import ChatModal from "./ChatModal";

function WhatsAppUserTable() {
  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: undefined,
  });
  const [pending, setPending] = useState(true);
  const [count, setCount] = useState(0);
  const [totalHitsValue, setTotalHitsValue] = useState(0);
  const [rowData, setRowData] = useState({});
  const [chatHistory, setChatHistory] = useState([])
  const [selectedChat, setSelectedChat] = useState(null);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [isTotalHitModalOpen, setIsTotalHitModalOpen] = useState(false);

  
    const handleStatusToggle = (id) => {
      
      const updatedRows = state.rows.map((row) =>
        row.id === id ? { ...row, status: !row.status } : row
      );
      const updatedRow = updatedRows.find((row) => row.id === id);
      setState((prevState) => ({ ...prevState, rows: updatedRows }));

      try {
        let _method = 'put';
          let url = `${URL_WITH_VERSION}marine/whatsapp_user_detail/${id}`;
          let payload = updatedRow
          postAPICall(url, payload, _method, (data) => {
            if (data) {
              openNotificationWithIcon(
                'success',
                data.msg,
              );
              setIsTotalHitModalOpen(false); 
            } else {
              openNotificationWithIcon('error', data.msg);
            }
          })
        
      } catch (error) {
        openNotificationWithIcon('error', errorInfo.message);
      }
    };

    const handleBlockToggle = (id) => {
      
      const updatedRows = state.rows.map((row) =>
        row.id === id ? { ...row, user_type: !row.user_type } : row
      );
      const updatedRow = updatedRows.find((row) => row.id === id);
      setState((prevState) => ({ ...prevState, rows: updatedRows }));

      try {
        let _method = 'put';
          let url = `${URL_WITH_VERSION}marine/whatsapp_user_detail/${id}`;
          let payload = updatedRow
          postAPICall(url, payload, _method, (data) => {
            if (data) {
              openNotificationWithIcon(
                'success',
                data.msg,
              );
              setIsTotalHitModalOpen(false); 
            } else {
              openNotificationWithIcon('error', data.msg);
            }
          })
        
      } catch (error) {
        openNotificationWithIcon('error', errorInfo.message);
      }
    };
  
    const handleContactClick = (contact) => {
      setSelectedChat(contact);
      setIsChatModalOpen(true);
      getChatHistory(contact)

    };

  const columns = [
    {
      name: "Contact No",
      selector: (row) => row?.contact_number,
      cell: (row) => <>
      <span>
      <span >
          {row?.contact_number}
        </span>
        <EyeOutlined style={{marginLeft:'1rem'}} onClick={()=>handleContactClick(row?.contact_number)} />
      </span>
      </>
        ,
                    
      sortable: true,
      isView: false,
      center: true,
    },
    {
      name: "Contact Name",
      selector: (row) => row?.contact_name,
      sortable: true,
      isView: false,
      center: true,
    },
    {
      name: "User Hits",
      selector: (row) => row?.user_hits,
      sortable: true,
      center: true,
    },
    {
      name: "Total Hits",
      selector: (row) => row?.total_hits,
      cell: (row) => <>
        <span >
          {row?.total_hits}
        </span>
        <span onClick={()=>handleTotalClick(row)}>
          <EditOutlined  style={{marginLeft:'1rem', cursor:'pointer'}}/>
        </span>
        </>,
      
      sortable: true,
      center: true,
    },
    {
      name: "User Status",
      selector: (row) => row?.user_type,
      sortable: true,
      center: true,
      cell: (row) =>
      <Switch
                  checked={row.user_type}
                  onChange={() => handleBlockToggle(row?.id)}
                  color="primary"
                />
    },
    {
      name: "Deactivate User",
      selector: (row) => row?.status,
      cell: (row) => <>
      <Switch
                  checked={row.status}
                  onChange={() => handleStatusToggle(row?.id)}
                  color="primary"
                />
                {row?.status ? <span className="status_tags Processingtags">Blocked</span> :   <span className="status_tags Approvedtags">Active</span> }
      </>,
      sortable: true,
      center: true,
    },
  ];

  useEffect(() => {
    getTableData();
  }, [count]);


  const getTableData = async () => {
    try {
      setPending(true);

      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}marine/whatsapp_user`,
        requestOptions
      );
      const respdata = await resp.json();
      setState((prevState) => ({
        ...prevState,
        rows: respdata,
      }));
      if (respdata) {
        setState((prevState) => ({ ...prevState, rows: respdata }));
        setPending(false);
      } else {
        openNotificationWithIcon("error", "Something went wrong.");
        setPending(false);

      }
    } catch (err) {
      console.error("Error fetching table data:", err); 
      openNotificationWithIcon("error", "Failed to fetch table data.");
      setPending(false);

    }
  };

  const getChatHistory = async (m_number) => {
    try {
      setPending(true);

      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}marine/whatsapp_user_msg/${m_number}`,
        requestOptions
      );
      const respdata = await resp.json();
     
      if (respdata) {
        setChatHistory(respdata)
        setPending(false);
      } else {
        openNotificationWithIcon("error", "Something went wrong.");
        setPending(false);

      }
    } catch (err) {
      console.error("Error fetching table data:", err); // Log the error
      openNotificationWithIcon("error", "Failed to fetch table data.");
      setPending(false);

    }
  };

  const handleTotalHitsChange = (value) => {
    setTotalHitsValue(value); 
  };

  const saveTotalHits = async () => {
    const updatedRows = state.rows.map((row) =>
      row.id === selectedChat ? { ...row, total_hits: totalHitsValue } : row
    );
    setState((prevState) => ({ ...prevState, rows: updatedRows }));
try {
  setPending(true);

  let _method = 'put';
    let url = `${URL_WITH_VERSION}marine/whatsapp_user_detail/${selectedChat}`;
    let payload = {...rowData,total_hits :totalHitsValue }
    postAPICall(url, payload, _method, (data) => {
      if (data) {
        openNotificationWithIcon(
          'success',
          data.msg,
        );
        setPending(false);

        setIsTotalHitModalOpen(false); 
      } else {
        openNotificationWithIcon('error', data.msg);
        setPending(false);

      }
    })
  
} catch (error) {
  openNotificationWithIcon('error', errorInfo.message);
  setPending(false);

}
  };

  const handleTotalClick = (userDetails) => {
    setRowData(userDetails)
    setSelectedChat(userDetails?.id)
    setTotalHitsValue(userDetails?.total_hits || 0); 
    setIsTotalHitModalOpen(true);
  };


  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <WhatsAppBulkMessageSender />
              </div>
              <br />
              <br />
              <Card>
                <MDBox key={count}>
                  <DataTable
                    columns={columns}
                    data={state?.rows}
                    pagination
                    progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
               

      <ChatModal isChatModalOpen={isChatModalOpen} setIsChatModalOpen={setIsChatModalOpen} chatData={chatHistory}  selectedChat={selectedChat} />

      {/* Modal for editing total hits */}
      <Modal open={isTotalHitModalOpen} onClose={() => setIsTotalHitModalOpen(false)}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      border: "2px solid #1976d2",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <Typography variant="h6" component="h4">
                      Edit Total Hits for {rowData?.contact_name}
                    </Typography>
                    <TextField
                      label="Total Hits"
                      type="number"
                      value={totalHitsValue}
                      onChange={(e) => handleTotalHitsChange(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={saveTotalHits}
                      sx={{ mt: 2 ,mx:4 , color:'#fff'}}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setIsTotalHitModalOpen(false)}
                      sx={{ mt: 2 , color:'#fff'}}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Modal>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

     
    </>
  );
}

export default WhatsAppUserTable;

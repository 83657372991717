// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import Footer from 'examples/Footer';

import DataTable from 'react-data-table-component';

// Data
import { useEffect, useState } from 'react';
import { getAPICall } from 'shared';
import { URL_WITH_VERSION } from 'shared';
import axios from 'axios';
import { RoomTwoTone } from '@mui/icons-material';
import { Button, Icon, Input, SvgIcon } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MDInputRoot from 'components/MDInput/MDInputRoot';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import AddCompanyForm from 'layouts/addCompany';
import {
  EditOutlined,
  EyeOutlined,
  FolderViewOutlined,
  FileAddOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { openNotificationWithIcon } from 'shared';
import { Badge, Tooltip } from '@material-ui/core';
import { message, Popconfirm } from 'antd';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import SubscribeOceannMail from './subscribeMail';
import Swal from 'sweetalert2';
import { postAPICall } from 'shared';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import { CheckIcon } from '@mantine/core';

// const EnableBanner  = (props)=>{
//   console.log(props);
//   // eslint-disable-next-line react/prop-types
//   let JsonPayload  =  props?.JsonPayload
//   // eslint-disable-next-line react/prop-types
//   const [Enable, setEnable] = useState(props?.JsonPayload[props.JsonKey]);
//   const label = { inputProps: { 'aria-label': 'controlled',value:true }  };
//   const [loading, setLoading] = useState(false);
//   const [disabled, setDisabled] = useState(false);
//   const  EnableOrDisableTheBanner  = async (data)=>{
//     let url =`${URL_WITH_VERSION}accounts/banner-change`
//     let Headers  =  {
//       "Content-Type": "application/json",
//     }
//     let payload    = {

//     }
//   }

//   return <>
//     <Switch
//       checked={Enable}
//       // disabled={disabled}
//       onChange={()=>{
//       setDisabled(true)
//       setLoading(true);
//       setEnable(!Enable)
//     }} {...label} />

//     {/* {loading && <CircularProgress size={16} style={{ marginLeft: 8 }} />} */}

//   </>

// }

// eslint-disable-next-line react/prop-types

const EnableBanner = (props) => {
  const { JsonPayload = {}, JsonKey = '' } = props;

  // Initial state setup
  const [Enable, setEnable] = useState(JsonPayload[JsonKey] || false);
  const label = { inputProps: { 'aria-label': 'controlled', value: true } };
  const EnableOrDisableTheBanner = async (newState) => {
    const url = `${URL_WITH_VERSION}accounts/banner-change`;
    const headers = {
      'Content-Type': 'application/json',
    };
    let payload;

    if (JsonKey == 'is_banner_visible_om') {
      payload = {
        company_id: props.row.id,
        [JsonKey]: newState,
        is_banner_visible_vm: props.row.is_banner_visible_vm,
      };
    } else if (JsonKey == 'is_banner_visible_vm') {
      payload = {
        company_id: props.row.id,
        [JsonKey]: newState,
        is_banner_visible_om: props.row.is_banner_visible_om,
      };
    }

    // console.log('Payload being sent:', payload);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      console.log('resp', data);
    } catch (error) {
      console.error('Error while updating banner state:', error);
    } finally {
      props.getTableData();
    }
  };

  return (
    <>
      <Switch
        checked={Enable}
        onChange={() => {
          const newState = !Enable;
          setEnable(newState);
          EnableOrDisableTheBanner(newState);
        }}
        {...label}
      />
      {/* {loading && <CircularProgress size={16} style={{ marginLeft: 8 }} />} */}
    </>
  );
};
EnableBanner.propTypes = {
  JsonPayload: PropTypes.object.isRequired,
  JsonKey: PropTypes.string.isRequired,
  getTableData: PropTypes.object.isRequired,
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    is_banner_visible_om: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    is_banner_visible_vm: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }).isRequired,
};

const ButtonTagS = ({ row }) => {
  const [userLoadin, setUserLoading] = useState(false);

  // eslint-disable-next-line react/prop-types
  if (row.is_vm_db_created === false) {
    return (
      <Button
        onClick={async (e) => {
          setUserLoading(true);
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');

          const raw = JSON.stringify({
            // eslint-disable-next-line react/prop-types
            id: row.id,
          });

          const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
          };
          let response = await fetch(
            `${URL_WITH_VERSION}accounts/create-vm-db`,
            requestOptions,
          );
          let data = await response.json();

          await Swal.fire('VM DB Script started please wait one minute...');
        }}
      >
        {userLoadin === true ? (
          <>
            <Spinner size="sm">Creating VM DB</Spinner>
            <span>Creating VM DB</span>
          </>
        ) : (
          <>Create VM DB</>
        )}
      </Button>
    );
  }
  return <Button disabled={true}> Create VM DB </Button>;
};

ButtonTagS.propTypes = {
  row: PropTypes.shape({
    is_vm_db_created: PropTypes.bool.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};

function CompanyList() {
  const [mailImport, setMailImport] = useState(false);
  const [state, setState] = useState({
    columns: [],
    rows: [],
    editData: undefined,
    isView: false,
  });
  const [isAddCompany, setIsAddComapny] = useState(false);
  const [filtered_items, setFiltereditems] = useState({});
  const [filterText, setfilterText] = useState('');
  const [pending, setPending] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  const [downloadModal, setDownloadModal] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [ModalOpenData, setModalOpenData] = useState({});
  const [userLoading, setUserLoading] = useState({});
  const [importMailList, setImportMailList] = useState([]);
  const [downLoadPaymentData, setdownLoadPaymentData] = useState([]);
  const prod =
    window.location.host.includes('.com') ||
    window.location.host.includes('localhost')
      ? false
      : true;

  // const filterdata = state?.rows?.filter((item) =>
  //   JSON.stringify(item).toLocaleLowerCase().includes(filterText.toLowerCase()),
  // )||[];

  function LoginAccessSwitch(props) {
    console.log(props);
    const [row, setRow] = useState({});
    const [key, setKey] = useState('');
    // eslint-disable-next-line react/prop-types
    const [Enabled, setEnabled] = useState(() => props.row[props.rowKey]);
    // eslint-disable-next-line react/prop-types
    console.log(props.key, props.row);
    useEffect(() => {
      // eslint-disable-next-line react/prop-types
      console.log(props.rowKey);
      // eslint-disable-next-line react/prop-types
      setEnabled(props.row[props.rowKey]);
    }, []);

    return (
      <>
        <Switch
          checked={Enabled}
          onChange={async (e) => {
            setEnabled(!Enabled);
            console.log(e.target.checked);
            // eslint-disable-next-line react/prop-types
            const payload = {};
            // eslint-disable-next-line react/prop-types
            let row_key = props.rowKey;
            // eslint-disable-next-line react/prop-types
            payload[props.rowKey] = e.target.checked;
            // eslint-disable-next-line react/prop-types
            let response = await fetch(
              `${URL_WITH_VERSION}accounts/company-data/${props.row['id']}`,
              {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ company_details: [payload] }),
              },
            );
            console.log(await response.json());
          }}
        />
      </>
    );
  }

  function Copy(url) {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    message.success('URL copied to clipboard');
  }

  const columns = [
    {
      name: <strong>Company Name</strong>,
      selector: (row) => row?.company_name,
      sortable: true,
      center: 'true',
      width: '150px',
    },
    {
      name: <strong>ANDROID ACCESS</strong>,
      selector: (row) => row.enable_android_login,
      sortable: true,
      center: 'true',
      cell: (row) => {
        return (
          <>
            <LoginAccessSwitch row={row} rowKey={'enable_android_login'} />
          </>
        );
      },
    },
    {
      name: <strong>IOS ACCESS</strong>,
      selector: (row) => row.enable_ios_login,
      sortable: true,
      center: 'true',
      cell: (row) => {
        return (
          <>
            <LoginAccessSwitch row={row} rowKey={'enable_ios_login'} />
          </>
        );
      },
    },
    {
      name: <strong>WEB LOGIN</strong>,
      selector: (row) => row.enable_web_login,
      sortable: true,
      center: 'true',
      cell: (row) => {
        return (
          <>
            <LoginAccessSwitch row={row} rowKey={'enable_web_login'} />
          </>
        );
      },
    },
    {
      name: <strong>WINDOWS LOGIN</strong>,
      selector: (row) => row.enable_web_login,
      sortable: true,
      center: 'true',
      cell: (row) => {
        return (
          <>
            <LoginAccessSwitch row={row} rowKey={'enable_web_login'} />
          </>
        );
      },
    },
    // {
    //   name: 'Company Address',
    //   selector: (row) => row?.company_address,
    //   sortable: true,
    //   center: 'true',
    //   cell: (row) => {
    //     if (row.company_address == null || row.company_address === undefined) {
    //       return <span style={{ color: 'red' }}>Not Available</span>;
    //     }
    //     return (
    //       <span
    //         style={{
    //           whiteSpace: 'nowrap',
    //           textOverflow: 'ellipsis',
    //           overflow: 'hidden',
    //         }}
    //       >
    //         {row.company_address}
    //       </span>
    //     );
    //   },
    // },
    {
      name: 'Processing Mail Count',
      selector: (row) => row?.initial_email_count,
      sortable: true,
      center: 'true',
    },
    {
      name: 'Import Emails',
      selector: (row) => row?.id,
      sortable: true,
      center: 'true',
      width: '200px',
      cell: (row) => {
        return (
          <>
            <FolderViewOutlined onClick={() => showimportEmails(row)} />
          </>
        );
      },
    },
    {
      name: 'Oceann VM URL',
      selector: (row) => row?.id,
      sortable: true,
      center: 'true',
      width: '200px',
      cell: (row) => {
        let name = row.company_name;
        if (name) {
          name = name.replace(/\s/g, '');
          if (row.VM_ACCESS) {
            return (
              <Tooltip placement="top" title="Click to Copy">
                <span
                  className="copy-url"
                  style={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    width: '200px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    color: 'navy',
                  }}
                  onClick={() =>
                    Copy(
                      `https://vm-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`,
                    )
                  }
                >
                  {`https://vm-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`}
                </span>
              </Tooltip>
            );
          } else {
            return <span style={{ color: 'red' }}>Not Subscribed</span>;
          }
        } else {
          return null;
        }
      },
    },
    {
      name: 'Admin URL',
      selector: (row) => row?.id,
      sortable: true,
      center: 'true',
      width: '200px',
      cell: (row) => {
        let name = row.company_name;
        if (name) {
          name = name.replace(/\s/g, '');
          return (
            <Tooltip placement="top" title="Click to Copy">
              <span
                className="copy-url"
                style={{
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  width: '200px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  color: 'navy',
                  fontWeight: 'bold',
                }}
                onClick={() =>
                  Copy(
                    `https://admin-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`,
                  )
                }
              >
                {`https://admin-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`}
              </span>
            </Tooltip>
          );
        } else {
          return null;
        }
      },
    },
    {
      name: 'Create VM DB',
      width: '12rem',
      center: true,
      cell: (row) => {
        // eslint-disable-next-line react/no-unknown-property
        return <ButtonTagS row={row} />;
      },
    },

    {
      name: 'OM URL',
      selector: (row) => row?.id,
      sortable: true,
      center: 'true',
      width: '200px',
      cell: (row) => {
        let name = row.company_name;
        if (name) {
          name = name.replace(/\s/g, '');
          if (row.MAIL_ACCESS) {
            return (
              <Tooltip placement="top" title="Click to Copy">
                <span
                  className="copy-url"
                  style={{
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    width: '200px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    color: 'navy',
                    fontWeight: 'bold',
                  }}
                  onClick={() =>
                    Copy(
                      `https://mail-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`,
                    )
                  }
                >
                  {`https://mail-${name.toString().trim().toLocaleLowerCase()}.theoceann.${prod ? 'ai' : 'com'}`}
                </span>
              </Tooltip>
            );
          } else {
            return <span style={{ color: 'red' }}>Not Subscribed</span>;
          }
        } else {
          return null;
        }
      },
    },
    {
      name: 'Email',
      selector: (row) => row?.email,
      sortable: true,
      center: 'true',
      width: '180px',
      cell: (row) => <span style={{ whiteSpace: 'nowrap' }}>{row?.email}</span>,
    },
    // {
    //   name: 'Phone No.',
    //   selector: (row) => row?.phone_number,
    //   sortable: true,
    //   center: 'true',
    // },
    {
      name: 'Status',
      selector: (row) => row?.status,
      sortable: true,
      width: '100px',
      center: 'true',
      cell: (row) => {
        if (row.status == 1 || row.status == true) {
          return (
            <Badge
              color="secondary"
              className="status-badge success"
              badgeContent="Active"
            />
          );
        }
        return (
          <Badge
            color="secondary"
            className="status-badge error"
            badgeContent="Deactivated"
          />
        );
      },
    },
    {
      name: 'VM Banner Visible',
      selector: (row) => {
        return row?.is_banner_visible_vm;
      },
      cell: (row) => {
        return (
          <>
            <EnableBanner
              getTableData={getTableData}
              row={row}
              JsonKey={'is_banner_visible_vm'}
              enable={row?.is_banner_visible_vm}
              JsonPayload={{
                is_banner_visible_vm: row.is_banner_visible_vm,
                is_banner_visible_om: row.is_banner_visible_om,
              }}
            />
          </>
        );
      },
    },
    {
      name: 'OM Banner Visible',
      selector: (row) => {
        return row?.is_banner_visible_om;
      },
      cell: (row) => {
        return (
          <>
            <EnableBanner
              getTableData={getTableData}
              row={row}
              JsonKey={'is_banner_visible_om'}
              enable={row?.is_banner_visible_om}
              JsonPayload={{
                is_banner_visible_vm: row.is_banner_visible_vm,
                is_banner_visible_om: row.is_banner_visible_om,
              }}
            />
          </>
        );
      },
    },
    {
      name: 'View',
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <EyeOutlined onClick={() => handleView(row)} />
        </MDTypography>
      ),
      right: true,
      width: '70px',
    },

    {
      name: 'Actions',
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <EditOutlined onClick={() => handleEdit(row)} />
        </MDTypography>
      ),
      center: true,
      width: '80px',
    },
    {
      name: 'Activate OM',
      center: true,
      cell: (row) => {
        if (row.MAIL_ACCESS) {
          return (
            <>
              <Button
                style={{ whiteSpace: 'nowrap' }}
                disabled={row.MAIL_ACCESS}
              >
                Already subscribed
              </Button>
            </>
          );
        } else {
          return (
            <Button
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => {
                setModalOpen(true);
                row['option'] = 'vm';
                setModalOpenData(row);
              }}
            >
              ACTIVATE
            </Button>
          );
        }
      },
    },
    {
      name: 'Activate OVM',
      center: true,
      cell: (row) => {
        if (row.VM_ACCESS) {
          return (
            <>
              <Button style={{ whiteSpace: 'nowrap' }} disabled={row.VM_ACCESS}>
                already subscribed
              </Button>
            </>
          );
        } else {
          return (
            <Button
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => {
                setModalOpen(true);
                row['option'] = 'mail';
                setModalOpenData(row);
              }}
            >
              ACTIVATE
            </Button>
          );
        }
      },
    },
    {
      name: 'Download-Create Invoice',
      center: true,
      cell: (row) => (
        <>
          <Popconfirm
            title="Please select product type "
            onConfirm={() => {
              setModalOpen(true);
              row['option'] = 'vm';
              setModalOpenData(row);
            }}
            onCancel={() => {
              setModalOpen(true);
              row['option'] = 'mail';
              setModalOpenData(row);
            }}
            okText="OM"
            cancelText="OVM"
            placement="topLeft"
            okButtonProps={{
              style: { backgroundColor: 'blue', color: 'white' }, // Style for OK button
            }}
            cancelButtonProps={{
              style: { backgroundColor: 'blue', color: 'white' }, // Style for Cancel button
            }}
          >
            <div style={{ color: 'blue', cursor: 'pointer' }}>
              <FileAddOutlined />
            </div>
          </Popconfirm>
          <div style={{ marginLeft: '6px' }}>
            <DownloadOutlined onClick={() => handleDownLoadInvoice(row?.id)} />
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    getTableData();
  }, [isAddCompany]);

  const getTableData = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      const resp = await fetch(
        `${URL_WITH_VERSION}accounts/company-details`,
        requestOptions,
      );
      const respdata = await resp.json();
      setTableData(respdata);
      setFilterData(respdata);
      if (respdata) {
        setState((prevState) => ({ ...prevState, rows: respdata }));
        setPending(false);
      } else {
        openNotificationWithIcon('error', 'Something went worng.');
      }
    } catch (err) {
      openNotificationWithIcon('error', err);
    }
  };
  const handleDownLoadInvoice = (companyId) => {
    setDownloadModal(true);
    console.log({ companyId });
    let url = `${URL_WITH_VERSION}accounts/download-invoice`;

    let _method = 'post';
    let payload = { id: companyId };

    postAPICall(url, payload, _method, (data) => {
      console.log(data);
      setdownLoadPaymentData(data);
      // if (data) {
      //   openNotificationWithIcon(
      //     'success',
      //     'Company Details Are saved Successfully',
      //   );

      // } else {
      //   openNotificationWithIcon('error', data.msg);
      // }
    });
  };

  const handleEdit = (row) => {
    setState((prevState) => ({ ...prevState, editData: row }));
    setIsAddComapny(true);
  };

  const handleView = (row) => {
    setState((prevState) => ({ ...prevState, editData: row, isView: true }));
    setIsAddComapny(true);
  };

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      editData: undefined,
      isView: false,
    }));
    setIsAddComapny(false);
  };

  const showimportEmails = async (row) => {
    console.log({ row });
    setMailImport(true);

    try {
      let _method = 'post';
      let url = `${URL_WITH_VERSION}accounts/company-wise-all-oceannmailaccount`;
      let payload = { id: row?.id };
      // let payload

      postAPICall(url, payload, _method, (data) => {
        console.log(data?.data);
        setImportMailList(data.data);
        if (data) {
          // openNotificationWithIcon(
          //   'success',
          //   'M Successfully',
          // );
          onCancel();
          form.resetFields();
        } else {
          openNotificationWithIcon('error', data.msg);
        }
      });
    } catch (err) {
      // openNotificationWithIcon('error', err);
    }

    // setState((prevState) => ({ ...prevState, editData: row }));
  };
  const { editData } = state;

  const downPaymentHistroy = [
    // {
    //   name: <strong>Company name</strong>,
    //   selector: (row) => row?.company?.company_name,
    //   sortable: true,
    //   center: true,
    // },
    {
      name: <strong>Download</strong>,
      cell: (row) => (
        <MDTypography
          component="a"
          variant="caption"
          color="blue"
          fontWeight="medium"
        >
          {row?.payment_description !== null && row?.payment_id ? (
            <DownloadOutlined
              onClick={() => {
                window.open(row?.payment_description?.pdf_url, '_blank');
              }}
            />
          ) : (
            <>No Invoice</>
          )}
        </MDTypography>
      ),
      right: true,
      width: '100px',
      center: true,
    },
    {
      name: 'Payment Id',
      selector: (row) => row?.session_id,
      center: true,
    },
    {
      name: 'Creation Time',
      selector: (row) => row?.created_at,
      sortable: true,
      cell: (row) =>
        row?.created_at ? (
          <span style={{ color: 'skyblue', fontWeight: '600' }}>
            {moment(row?.created_at).format('DD-MM-YYYY')}
          </span>
        ) : (
          'N/A'
        ),
      center: true,
    },
    // {
    //   name: "Updated Time",
    //   selector: (row) => row?.updated_at,
    //   sortable: true,
    //   cell: (row) => row?.updated_at ? <span style={{ color: 'brown', fontWeight: '600' }}>{moment(row?.updated_at).format("DD-MM-YYYY")}</span> : "N/A",
    //   center: true,
    // },

    {
      name: 'Product Name',
      selector: (row) => row?.product_name,
      center: true,
    },
    {
      name: 'Payment Method',
      selector: (row) => row?.type,
      center: true,
    },
    {
      name: 'Amount',
      selector: (row) => row?.total_amount,
      sortable: true,
      center: true,
    },
    {
      name: 'Status',
      selector: (row) => row?.payment_status,
      sortable: true,
      center: true,
      cell: (row) => {
        let status = row?.payment_status;
        if (status == 1) {
          return <span className="status_tags Activetags">ACTIVE</span>;
        }
        return <span className="status_tags Processingtags">Deactivated</span>;
      },
    },
  ];

  return (
    <>
      {ModalOpen === true ? (
        <>
          <SubscribeOceannMail row={ModalOpenData} modalState={setModalOpen} />
        </>
      ) : (
        <></>
      )}
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    minWidth: '200px',
                    maxWidth: '600px',
                  }}
                  onChange={(e) => {
                    setfilterText(e.target.value);
                    setFilterData(
                      tableData.filter((item) =>
                        JSON.stringify(item)
                          .toLocaleLowerCase()
                          .includes(e.target.value.toLocaleLowerCase()),
                      ),
                    );
                  }}
                />
                {/* <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddComapny(true)}
                >
                  Add Company
                </MDButton> */}
              </div>
              <br />
              <br />
              <Card>
                <MDBox>
                  <DataTable
                    columns={columns}
                    data={filterdata}
                    pagination
                    progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>

      <Modal
        isOpen={mailImport}
        toggle={() => setMailImport(false)}
        backdrop="false"
        centered
        modalClassName="top-modal"
      >
        <ModalHeader toggle={() => setMailImport(false)}>
          Imported Email
        </ModalHeader>
        {importMailList?.length > 0 ? (
          <ModalBody>
            {importMailList.map((mail, index) => (
              <p key={index}>{mail?.import_email}</p>
            ))}
          </ModalBody>
        ) : (
          <ModalBody>
            <p> No Emails Found</p>
          </ModalBody>
        )}
      </Modal>

      {isAddCompany === true ? (
        <AddCompanyForm
          visible={true}
          onCancel={onCancel}
          editData={editData}
          isView={state?.isView}
        />
      ) : undefined}
      {downloadModal && (
        <div>
          <Modal
            isOpen={downloadModal}
            toggle={() => setDownloadModal(false)}
            backdrop="false"
            centered
            size="lg"
          >
            <ModalHeader toggle={() => setDownloadModal(false)}>
              Download invoice
            </ModalHeader>

            <ModalBody>
              <Card>
                <MDBox>
                  <DataTable
                    columns={downPaymentHistroy}
                    data={downLoadPaymentData}
                    pagination
                    progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
              </Card>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  );
}

CompanyList.propTypes = {
  row: PropTypes.shape({
    is_vm_db_created: PropTypes.bool.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};
export default CompanyList;
